// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

// Place any jQuery/helper plugins in here.


// @codekit-prepend "../../../../../../node_modules/jquery/dist/jquery.js" quiet;
// @codekit-prepend "../../../../../../node_modules/jquery-migrate/dist/jquery-migrate.js" quiet;


// @codekit-prepend "../../../../../../node_modules/jquery-visible/jquery.visible.min.js" quiet;

// @codekit-prepend "../../../../../../node_modules/aos/dist/aos.js

// @codekit-prepend "jquery.cycle2.js" quiet;
// @codekit-prepend "jquery.cycle2.carousel.js" quiet;
// @codekit-prepend "jquery.cycle2.center.js" quiet;
// @codekit-prepend "jquery.cycle2.swipe.js" quiet;
// @codekit-prepend "jquery.cycle2.autoheight.js" quiet;


// ----------------------------------------------------------------------------------------- 
jQuery.noConflict();
(function($) {	
	
	// mobile size function -- :before to class ------------------------------------------
	// transform the css :after mobile to a more usable class mobile
	function classMobile() {
		
		var size = window.getComputedStyle(document.body,':before').getPropertyValue('content');
		
		size = size.replace(/"/g, '');
		
		if (size == 'mobile')  {
	 		$('body').addClass('mobile');
	 		$('body').removeClass('screen');
	 	}  else if (size == 'screen') {
		 	$('body').addClass('screen');
		 	$('body').removeClass('mobile');
	 	} else { 
			$('body').removeClass('mobile');
			$('body').removeClass('screen'); 
	 	}	
	}
	
	classMobile();
		
	// burger nav --------------------------------------------------------------------------
	
	// nav functions 
	function nav_reset() {
		if( $('body').hasClass('mobile')  ) {
			$('header nav .sub-menu').hide();
		} else {
			$('header nav .sub-menu').show();
			$('header nav .menu-item-has-children').find('.sub-menu-button').remove();
		}
		
		$('header nav .menu-item-has-children').removeClass('is-open');
		
	}
	
	function nav_sub_menu() {
		
		if( $('body').hasClass('mobile')  ) {
			$('header nav .sub-menu').hide();
			$('header nav .menu-item-has-children').prepend('<span class="sub-menu-button">+</span>');
		} else {
			$('header nav .sub-menu').show();
			$('header nav .menu-item-has-children').removeClass('is-open');
			$('header nav .menu-item-has-children').find('.sub-menu-button').remove();
		}
	}
	
	nav_sub_menu();
	
	$(document).on('click', '.hamburger', function(){
		$('.hamburger').toggleClass('is-active');
		$('body').toggleClass('bodyfix');
		$('header nav').toggleClass('is-active');
		$('header').toggleClass('menu-active');
		
		nav_reset();
		
		console.log('click burger');
	});
	
	// close the menu with ESC key
	$(document).on('keyup',function(evt) {
		if (evt.keyCode == 27) {
			
			// burger navigation
			$('body').removeClass('bodyfix'); 
			$('.hamburger').removeClass('is-active');
			$('header nav').removeClass('is-active');
			$('header').removeClass('menu-active');
			
			nav_reset();	

			 console.log('esc close menu');
		}
	});


	
	// ------------------------------------------------------------------------------------- 
	// smartphone navigation 
	
	// open close sub-menu on smartphone sizes
	$('.mobile header nav .menu-item-has-children > a').on( 'click', function(e) {
		
		if( !$(this).parent().hasClass('is-open') ){
			e.preventDefault();
		}	
		//e.stopPropagation();
		//e.stopImmediatePropagation();
		
		$(this).parent().find('.sub-menu').slideDown(100);
		$(this).parent().addClass('is-open');
		
		$(this).parent().siblings().find('.sub-menu').slideUp(100);
		$(this).parent().siblings().removeClass('is-open');	
		
	});

	$('.mobile header nav .menu-item-has-children .sub-menu-button').on( 'click', function(e) {
		
		//e.preventDefault();
		//e.stopPropagation();
		//e.stopImmediatePropagation();
		if( $(this).parent().hasClass('is-open') ){
			
			$(this).parent().find('.sub-menu').slideUp(100);
			$(this).parent().removeClass('is-open');
			
		} else {
			$(this).parent().find('.sub-menu').slideDown(100);
			$(this).parent().addClass('is-open');
		
			$(this).parent().siblings().find('.sub-menu').slideUp(100);
			$(this).parent().siblings().removeClass('is-open');	
		}
		
	});
	
	
	// ------------------------------------------------------------------------------------- 

	// Hide header on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $('header .logo').outerHeight();
	
	
	$(window).scroll(function(event){
	    didScroll = true;
	});
	
	setInterval(function() {
	    if (didScroll) {
	        hasScrolled();
	        didScroll = false;
	    }
	}, 250);
	
	var is_home = false;
	var page_head_height = $('.pagehead').outerHeight();
	
	console.log('page_head_height ' + page_head_height);
	
	if(typeof comment === 'undefined') {
   		var page_head_height = 1;
	} else if(comment === null){
	    var page_head_height = 1;
	}
		
	function hasScrolled() {
	    var st = $(this).scrollTop();
	    
	    // Make scroll more than delta
	    if(Math.abs(lastScrollTop - st) <= delta)
	        return;
	    
	    // If scrolled down and past the navbar, add class .nav-up.
	    if (st > lastScrollTop && st > navbarHeight){
	        // Scroll Down
	        $('header').removeClass('nav-up').addClass('nav-down');
	    } else {
	        // Scroll Up
	        if(st + $(window).height() < $(document).height()) {
	            $('header').removeClass('nav-down').addClass('nav-up');
	        }
	        
	        // console.log(st);
	        // hide the sticky nav before reaching the pagehead
	        if(st < page_head_height) {
	            //$('header').removeClass('nav-down').removeClass('nav-up');
	            $('header').addClass('hide-sticky-background');
	        } else {
		        $('header').removeClass('hide-sticky-background');
	        }
	    }
	  
	    lastScrollTop = st;
	}




	// ------------------------------------------------------------------------------------- 
	// smoth scroll to top
	$('a[href^="#"]').on('click',function(e) {
	 e.preventDefault();
	 var target = this.hash;
	 var $target = $(target);
	 $('html, body').stop().animate({
	  'scrollTop': $target.offset().top
	 }, 900, 'swing', function () {
	  window.location.hash = target;
	 });
	});
	// -------------------------------------------------------------------------------------
	
	
	
	// -------------------------------------------------------------------------------------
	// show and hide caption 
	$('figure').on('click', 'button', function(){
		$(this).parent().toggleClass('caption-visible');
	});
	// -------------------------------------------------------------------------------------
	
	
	
	// -------------------------------------------------------------------------------------
	// accordion
	$('.accordion .detail').hide();
	
	$(document).on('click', '.accordion .title, .accordion button', function(){
	
		$(this).closest('.item').find('.detail').slideToggle(400);
		$(this).closest('.item').toggleClass('is-open').toggleClass('is-closed');
		
		$(this).closest('.item').siblings().removeClass('is-open');
		$(this).closest('.item').siblings().addClass('is-closed');
		$(this).closest('.item').siblings().find('.detail').slideUp(400);

	});
	// -------------------------------------------------------------------------------------
	// quote in view - add classes to animate headline
	// https://stackoverflow.com/questions/41144113/javascript-jquery-add-class-when-element-comes-into-viewport
	
	function isScrolledIntoView(elem) {
	  var docViewTop = $(window).scrollTop();
	  var docViewBottom = docViewTop + $(window).height();
	
	  var elemTop = $(elem).offset().top;
	  var elemBottom = elemTop + $(elem).height();
	
	  return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
	}
	
	$(window).on('scroll', function() {
	  $(".quote").each(function() {
	    if (isScrolledIntoView($(this))) {
	     	 $(this).find('.quote-wrapper').addClass("aninmated-headline");
		 	 var colors = $(this).find('.quote-wrapper').data('colors');
		 	 $(this).find('.quote-wrapper').addClass(colors);
	    }
	  });
	});
		
	// -------------------------------------------------------------------------------------
	
	
	// -------------------------------------------------------------------------------------
	// news load more with ajax
	
	var current_page_loaded = 1;
	var max_num_pages = $('#load-more-news').data('max-num-pages');
		
	$(document).on('click', '#load-more-news', function(){

		var data = {
			'action': 'aquadrat_loadmore_news',
			'query': aquadrat_ajax_object.posts,
			'page' : aquadrat_ajax_object.current_page,
			'nonce' : aquadrat_ajax_object.nonce,		
		};			
		
		console.log('load-more start');
		$.ajax({
			url : aquadrat_ajax_object.ajaxurl,
			data:data,
			type:'POST',
			beforeSend: function( xhr ){
				// you can also add your own preloader here
				// you see, the AJAX call is in process, we shouldn't run it again until complete
				$('.loader-wrapper').show();
				
			},
			success:function(data){
				
				current_page_loaded = current_page_loaded + 1;
				
				//console.log('current_page_loaded' + current_page_loaded);
				//console.log('load-more success');
				
				if( data ) {
					$('.news-archive  .news').append( data ); // where to insert posts
					aquadrat_ajax_object.current_page++;
					
					$('.loader-wrapper').hide();
				}
				
				// hide the load more button when last page reached
				if (current_page_loaded >= max_num_pages) {
					$('#load-more-news').hide(200);	
				}	
			}
		});
		// -------------------------------------------------------------------------
					
	});
	// -------------------------------------------------------------------------------------
	
	// prevent saving images, disable the contextmenu for img tags
	// https://pretagteam.com/question/disable-save-image-option
	$(document).on('contextmenu', 'img', function() {
      return false;
  })
	
	//-------------------------------------------------------------------------------------------------------------------------
	// all resize functions 
	//-------------------------------------------------------------------------------------------------------------------------
	
	var resizeTimer;
	$(window).on('resize', function(e) {
	  clearTimeout(resizeTimer);
	  resizeTimer = setTimeout(function() {
	
	   	classMobile();
	   	nav_sub_menu();
	   	nav_reset();		            
	  }, 500);
	  
	});

	
	// -------------------------------------------------------------------------------------
	// init AOS animations 
	// https://michalsnik.github.io/aos/
	// https://github.com/michalsnik/aos/tree/v2
	AOS.init({
      disable: 'mobile'
    });
	// -------------------------------------------------------------------------------------
	
}(jQuery));
